export const sreEcadin = [
  'OCT 2|TOWARDS INCLUSIVE ENERGY TRANSITION: Expanding Access to Clean Energy|SRE Telkom University',
  'OCT 3|Innovation to Empower Islands through Solar Energy|SRE Universitas Tanjungpura',
  'OCT 4|TOWARDS MASSIVE TRANSFORMATION OF NATIONAL EV|SRE ITS',
  'OCT 9|GEOTHERMAL FOR ECONOMIC GROWTH|SRE UGM',
  'OCT 10|SUSTAINABLE BIODIESEL DEVELOPMENT|SRE Universitas Sriwijaya',
  'OCT 11|BIOGAS DEVELOPMENT AND GOVERNANCE|SRE Universitas Brawijaya',
  'OCT 16|WIND ENERGY: LATEST TRENDS AND OPPORTUNITIES|SRE Universitas Hasanuddin',
  'OCT 17|RECYCLING AND WASTE-TO-ENERGY|SRE UNS',
  'OCT 18|Enabling a Low-Carbon Economy through Solar Energy|SRE ITERA',
  'OCT 23|RENEWABLE ENERGY FOR SMART CITY|SRE UI',
  'OCT 24|THE POWER OF COMMUNITIES FOR ENERGY TRANSITION|SRE ITB',
  'OCT 25|HR READINESS FOR NATIONAL RE INDUSTRIES|SRE UNDIP'
]