import { Link } from 'gatsby';
import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { FormInput, FormSelect } from '@common';
import { Button } from '@components/global';
import { teamData } from '@sections/Contact/content';
import { useFormInput, changeToUrl } from '@utils';
import { FormContainer } from './style';

const Register = ({ title }) => {
  const nama = useFormInput('');
  const email = useFormInput('');
  const confirmEmail = useFormInput('');
  const telepon = useFormInput('');
  const [umur, setUmur] = useState('Select');
  const [selectedInfoSource, setSelectedInfoSource] = useState('Select');
  const [selectedUniv, setSelectedUniv] = useState('Select');
  const optionListUmur = [
    '<17 Tahun',
    '17-23 Tahun',
    '23-30 Tahun',
    '>30 Tahun',
  ];
  const optionListInfoSource = [
    'Kampus',
    'Teman',
    'Instagram',
    'Whatsapp',
    'Website',
  ];
  const optionListUniv = Object.keys(teamData)
    .map(team => team.replace('_', '-'))
    .concat(['Universitas lain', 'Sekolah Menengah', 'Umum']);

  const [isAgree, setIsAgree] = useState(false);
  const [isEmailAndNameTrue, setIsEmailAndNameTrue] = useState(false);
  const [isExpandUniv, setExpandUniv] = useState(false);
  const [sendForm, setSendForm] = useState({ sent: false, success: false });

  const updateRegistrantCounter = async () => {
    const ref = firebase
      .database()
      .ref(`/events/${changeToUrl(title)}/count_registrant`);
    new Promise((resolve) => {
      ref.once('value', snapshot => resolve(snapshot.val()+1));
    }).then(newCounter => ref.set(newCounter));
  };

  const sendData = async () => {
    if (
      nama.value === '' ||
      email.value === '' ||
      telepon.value === '' ||
      umur === 'Select' ||
      selectedUniv === 'Select'
    ) {
      alert(
        'Anda wajib mengisi nama, email, telepon, umur, dan asal universitas.'
      );
    } else if (!isEmailAndNameTrue) {
      alert(
        'Anda wajib menyatakan bahwa nama dan email telah tertulis dengan benar.'
      );
    } else if (!isAgree) {
      alert(
        'Anda wajib menyatakan bahwa Anda sudah membaca alur pendaftaran dengan seksama dan sudah melakukan syarat pendaftaran.'
      );
    } else if (email.value !== confirmEmail.value) {
      alert('Mohon periksa kembali email Anda.');
    } else {
      await firebase
        .database()
        .ref(
          `/events/${changeToUrl(title)}/registrant/${nama.value}-${
            telepon.value
          }`
        )
        .set({
          nama: nama.value,
          email: email.value,
          telepon: telepon.value,
          umur: umur,
          university: selectedUniv === 'Select' ? '' : selectedUniv,
          infoSource: selectedInfoSource === 'Select' ? '' : selectedInfoSource,
          expandUniv: isExpandUniv,
        })
        .then(() => {
          setSendForm({ sent: true, success: true });
          updateRegistrantCounter();
        })
        .catch(() => {
          setSendForm({ sent: true, success: false });
        });
    }
  };

  return (
    <>
      <h2>Pendaftaran Event</h2>
      <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{title}</h3>
      <FormContainer>
        <div>
          <FormInput data={nama} type="text" label="Nama" />
          <FormInput data={email} type="email" label="Email" />
          <FormInput
            data={confirmEmail}
            type="email"
            label="Konfirmasi Email"
          />
          <FormInput data={telepon} type="number" label="No. Telepon" />
          <FormSelect
            title="Umur"
            selected={umur}
            setSelected={setUmur}
            optionList={optionListUmur}
          />
          <FormSelect
            title="Asal"
            selected={selectedUniv}
            setSelected={setSelectedUniv}
            optionList={optionListUniv}
          />
          <FormSelect
            title="Dari mana Anda mengetahui tentang SRE?"
            selected={selectedInfoSource}
            setSelected={setSelectedInfoSource}
            optionList={optionListInfoSource}
          />
          <div>
            <input
              type="checkbox"
              checked={isAgree}
              onChange={() => {
                setIsAgree(!isAgree);
              }}
            />{' '}
            Saya sudah membaca alur pendaftaran dengan seksama dan sudah
            melakukan syarat pendaftaran. <br />
            <p style={{ marginTop: '8px' }}>
              Link syarat:{' '}
              <a
                href="http://bit.ly/TwibbonGLSRE"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                bit.ly/TwibbonGLSRE
              </a>
            </p>
          </div>
          {selectedUniv === 'Universitas lain' ? (
            <div style={{ marginTop: '8px' }}>
              <input
                type="checkbox"
                checked={isExpandUniv}
                onChange={() => {
                  setExpandUniv(!isExpandUniv);
                }}
              />{' '}
              Ingin dirikan SRE di kampusmu?
            </div>
          ) : null}
          <div style={{ marginTop: '8px' }}>
            <input
              type="checkbox"
              checked={isEmailAndNameTrue}
              onChange={() => {
                setIsEmailAndNameTrue(!isEmailAndNameTrue);
              }}
            />{' '}
            Saya menyatakan bahwa email dan nama yang tertulis sudah benar.{' '}
            <br />
          </div>
        </div>
      </FormContainer>
      <div style={{ textAlign: 'center', marginTop: '32px' }}>
        <Link to={'..'}>
          <Button style={{ marginRight: '8px' }}>Kembali</Button>
        </Link>
        {!sendForm.sent ? (
          <Button onClick={sendData}>Kirim</Button>
        ) : (
          <p style={{ marginTop: '12px', fontWeight: 'bold' }}>
            {sendForm.success
              ? 'Sukses !'
              : 'Gagal, Silakan input ulang data yang valid'}
          </p>
        )}
      </div>
    </>
  );
};

export default Register;