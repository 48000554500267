import React from 'react';
import GLRegister from './GLRegister';
import SeriesRegister from './SeriesRegister';
import { sreEcadin } from './SeriesData';

const Register = ({ title, template }) => {
  let templateComponent = <GLRegister title={title} />; /* Todo: ganti jadi default template */
  if (template === 'GL') {
    templateComponent = <GLRegister title={title} />;
  }
  if (template === 'Series') {
    templateComponent = <SeriesRegister title={title} seriesData={sreEcadin} />;
  }
  return templateComponent;
};

export default Register;