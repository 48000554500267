import styled from 'styled-components';
export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;

  & > div {
    width: 60%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    & > div {
      width: 100%;
    }
  }
`;

export const FormSubtext = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.danger};
  margin-bottom: 1rem;
`;