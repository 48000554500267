import { Link } from 'gatsby';
import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { FormInput, FormSelect } from '@common';
import { Button } from '@components/global';
import { teamData } from '@sections/Contact/content';
import { useFormInput, changeToUrl } from '@utils';
import { FormContainer, FormSubtext } from './style';

const Register = ({ title, seriesData }) => {
  const nama = useFormInput('');
  const email = useFormInput('');
  const confirmEmail = useFormInput('');
  const telepon = useFormInput('');
  const [selectedUniv, setSelectedUniv] = useState('Select');
  const optionListUniv = Object.keys(teamData)
    .map(team => team.replace('_', '-'))
    .concat(['Universitas lain', 'Sekolah Menengah', 'Umum']);

  const [isEmailAndNameTrue, setIsEmailAndNameTrue] = useState(false);
  const [sendForm, setSendForm] = useState({ sent: false, success: false });
  const [selectedWebminar, setSelectedWebminar] = useState([]);

  const handleCheckboxes = (e) => {
    if (e.target.checked) {
      setSelectedWebminar([...selectedWebminar, e.target.value]);
    } else {
      const tempArr = selectedWebminar;
      const removeIdx = tempArr.indexOf(e.target.value);
      if (removeIdx !== -1) {
        tempArr.splice(removeIdx, 1);
        setSelectedWebminar(tempArr);
      }
    }
  }

  const updateRegistrantCounter = async () => {
    const ref = firebase
      .database()
      .ref(`/events/${changeToUrl(title)}/count_registrant`);
    new Promise((resolve) => {
      ref.once('value', snapshot => resolve(snapshot.val()+1));
    }).then(newCounter => ref.set(newCounter));
  };

  const sendData = async () => {
    if (
      nama.value === '' ||
      email.value === '' ||
      telepon.value === '' ||
      selectedUniv === 'Select'
    ) {
      alert(
        'Anda wajib mengisi nama, email, telepon, dan asal universitas.'
      );
    } else if (!isEmailAndNameTrue) {
      alert(
        'Anda wajib menyatakan bahwa nama dan email telah tertulis dengan benar.'
      );
    } else if (email.value !== confirmEmail.value) {
      alert('Mohon periksa kembali email Anda.');
    } else {
      await firebase
        .database()
        .ref(
          `/events/${changeToUrl(title)}/registrant/${nama.value}-${
            telepon.value
          }`
        )
        .set({
          nama: nama.value,
          email: email.value,
          telepon: telepon.value,
          university: selectedUniv === 'Select' ? '' : selectedUniv,
          selectedWebminar: selectedWebminar
        })
        .then(() => {
          setSendForm({ sent: true, success: true });
          updateRegistrantCounter();
        })
        .catch(() => {
          setSendForm({ sent: true, success: false });
        });
    }
  };

  return (
    <>
      <h2>Pendaftaran Event</h2>
      <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{title}</h3>
      <FormContainer>
        <div>
          <FormInput data={nama} type="text" label="Nama" />
          <FormInput data={email} type="email" label="Email" />
          <FormInput
            data={confirmEmail}
            type="email"
            label="Konfirmasi Email"
            noMargin={confirmEmail.value !== '' && email.value !== confirmEmail.value}
          />
          {confirmEmail.value !== '' && email.value !== confirmEmail.value ? <FormSubtext>Konfirmasi email tidak sesuai</FormSubtext> : null}
          <FormInput data={telepon} type="number" label="No. Telepon" />
          <FormSelect
            title="Asal"
            selected={selectedUniv}
            setSelected={setSelectedUniv}
            optionList={optionListUniv}
          />
          <div style={{ marginTop: '8px' }}>
            <h4 style={{ marginBottom: '8px' }}>Seri Webminar yang ingin diikuti:</h4>
            <div>
              {seriesData.map((data, index) => {
                const [eventDate, eventName, eventOrganizer] = data.split('|');
                return (
                <div key={index}>
                  <input
                  type="checkbox"
                  value={index+1}
                  disabled={index<=11}
                  onChange={handleCheckboxes}
                  />{` Webminar ${index+1}`}<br/>
                  <p><b>{eventName}</b> <br/> {eventDate} | By: {eventOrganizer}</p>
                  <br />
                </div>
              )})}
            </div>
          </div>
          <div style={{ marginTop: '8px' }}>
            <input
              type="checkbox"
              checked={isEmailAndNameTrue}
              onChange={() => {
                setIsEmailAndNameTrue(!isEmailAndNameTrue);
              }}
            />{' '}
            Saya menyatakan bahwa email dan nama yang tertulis sudah benar.{' '}
            <br />
          </div>
        </div>
      </FormContainer>
      <div style={{ textAlign: 'center', marginTop: '32px' }}>
        <Link to={'..'}>
          <Button style={{ marginRight: '8px' }}>Kembali</Button>
        </Link>
        {!sendForm.sent ? (
          <Button onClick={sendData}>Kirim</Button>
        ) : (
          <p style={{ marginTop: '12px', fontWeight: 'bold' }}>
            {sendForm.success
              ? 'Sukses !'
              : 'Gagal, Silakan input ulang data yang valid'}
          </p>
        )}
      </div>
    </>
  );
};

export default Register;