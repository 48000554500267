import { graphql } from 'gatsby';
import React from 'react';
import { Layout } from '@common';
import { Container } from '@components/global';
import { StyledSection, EndEventContainer } from './style';
import Register from '@sections/Register';

const EventTemplate = ({ data }) => {
  const event = data.event;
  const oneHour = 3600000;
  return (
    <Layout page={'Daftar ' + event.title} includeHeading={false}>
      <StyledSection>
        <Container>
          {new Date().getTime() < Number(event.timestamp)-oneHour ? (
            <Register title={event.title} template={event.registerTemplate} />
          ) : (
            <EndEventContainer>
              <h3>
                Pendaftaran Event <br />
                {event.title} <br />
                telah berakhir
              </h3>
            </EndEventContainer>
          )}
        </Container>
      </StyledSection>
    </Layout>
  );
};

export default EventTemplate;
export const pageQuery = graphql`
  query RegisterEventById($id: String!) {
    event(id: { eq: $id }) {
      title
      tanggal: date(formatString: "dddd, DD MMMM YYYY", locale: "id")
      timestamp: date(formatString: "x")
      registerTemplate: hidden_tag
    }
  }
`;
